import React, { useState, useEffect } from 'react'
import dropin from 'braintree-web-drop-in'
import axios from 'axios'
import { toPrice } from '../helpers'

import { ChipBoxContainer, ChipBox, ButtonBox } from './styles'

const PaymentForm = ({
  totalAmount: amount,
  totalHours,
  logToDiscord,
  showNotification,
  apiUrl,
  currencyBadge,
}) => {
  const [dropinInstance, setDropinInstance] = useState(null)
  const [isPayDisabled, setPayDisabled] = useState(true)

  const getClientToken = async () => {
    try {
      const { data } = await axios({
        method: 'post',
        url: `${apiUrl}/client-token`,
      })
      const { clientToken } = data
      return clientToken
    } catch (e) {
      console.error(e)
      showNotification('error', e.message)
    }
  }

  const createDropInInstance = async clientToken => {
    try {
      const dropinInstance = await dropin.create({
        authorization: clientToken,
        selector: '#dropin-container',
      })
      setDropinInstance(dropinInstance)
    } catch (e) {
      console.error(e)
      showNotification('error', e.message)
    }
  }

  const processPayment = async nonce => {
    try {
      const { data } = await axios({
        method: 'post',
        url: `${apiUrl}/process-transaction`,
        data: { amount, nonce },
      })
      return data
    } catch (e) {
      console.error(e)
      showNotification('error', e.message)
    }
  }

  const handlePayNow = async () => {
    if (!amount) {
      showNotification('error', 'Please select at least one item')
      return console.error('Please select at least one item')
    }
    setPayDisabled(true)
    try {
      const { nonce } = await dropinInstance.requestPaymentMethod()
      const { success, message } = await processPayment(nonce)
      await dropinInstance.clearSelectedPaymentMethod()
      await logToDiscord(amount, !success ? message : 'Payment successful!')
      showNotification(
        success ? 'success' : 'error',
        success ? 'Payment successful!' : message
      )
    } catch (e) {
      console.error(e)
      showNotification('error', e.message)
    }
    setPayDisabled(false)
  }

  useEffect(() => {
    async function brainTreeInit() {
      try {
        const clientToken = await getClientToken()
        await createDropInInstance(clientToken)
      } catch (e) {
        console.error(e)
        showNotification('error', e.message)
      }
    }
    brainTreeInit()
  }, [])

  useEffect(() => {
    setPayDisabled(!amount)
  }, [amount])

  return (
    <>
      <div id="dropin-container"></div>
      <ChipBoxContainer>
        <ChipBox variant="outlined" label={`Hours total: ${totalHours}`} />
        <ChipBox
          variant="outlined"
          label={`Amount total: ${currencyBadge}${toPrice(amount)}`}
        />
      </ChipBoxContainer>
      <ChipBoxContainer>
        <ButtonBox
          variant="contained"
          size="small"
          color="primary"
          onClick={handlePayNow}
          disabled={isPayDisabled}
        >
          PAY NOW
        </ButtonBox>
      </ChipBoxContainer>
    </>
  )
}

export default PaymentForm
