import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'



export const TextFieldLabel = styled(TextField)`
  width: 100%;
  max-width: 400px;
  padding-right: 20px!important; 

  label + .MuiInput-formControl{
    margin-top: 0!important;
  }
  .MuiInputLabel-formControl{
    transform: translate(0, -14px) scale(1)!important;
  }
  .MuiFormLabel-root.Mui-focused{
    transform: translate(0, -16px) scale(1)!important;
  }

`