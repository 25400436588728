import axios from 'axios'

export const toDiscord = async (content = 'empty message') => {
  try {
    const webhookUrl =
      'https://discordapp.com/api/webhooks/588054021844238336/UZ_0YZ8FDQ53im0xV_VqRj9I8MbqQP2QJBdHYz7m-SzK5Zo0rHGS01hSLt-vVK75XrrG'
    await axios({
      method: 'post',
      url: webhookUrl,
      data: { content },
    })
  } catch (e) {
    console.error(e)
  }
}

// TODO: implement
export const toPrice = x => {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join('.')
}
