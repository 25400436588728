import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'



export const TextFieldLabel = styled(TextField)`
  width: 100%;
  max-width: 400px;
  padding-right: 20px!important; 

`