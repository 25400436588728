import React, { useEffect } from 'react'
import List from '@material-ui/core/List'
import CustomerInfo from '../customerInfo/CustomerInfo'
import OrderItem from '../orderItem/OrderItem'
import OfferItem from '../offerItem/OfferItem'
import OfferCustomItem from '../offerCustomItem/OfferCustomItem'
import { toPrice } from '../helpers'

import {
  ContainerSection,
  TypographyText,
  ChipBoxContainer,
  ChipBox,
  ListItemBox,
} from './styles'

const OrderCreator = ({
  setTotalAmount,
  totalAmount,
  setTotalHours,
  totalHours,
  setOrderList,
  customerName,
  setCustomerName,
  customerCompanyName,
  setCustomerCompanyName,
  customerTax,
  setCustomerTax,
  customerEmail,
  setCustomerEmail,
  orderList,
  currencyBadge,
}) => {
  const offerList = [
    { title: 'Solutions Architect', rate: 120 },
    { title: 'FullStack Developer', rate: 60 },
    { title: 'Front End Developer', rate: 35 },
    { title: 'React Developer', rate: 50 },
    { title: 'Mobile Developer', rate: 60 },
    { title: 'DevOps', rate: 60 },
    { title: 'Manual QA Engineer', rate: 15 },
    { title: 'Automated QA Engineer', rate: 25 },
    { title: 'Project Manager', rate: 60 },
    { title: 'Web Designer', rate: 30 },
    { title: 'Graphic Designer', rate: 20 },
    { title: 'UX/UI Designer', rate: 50 },
  ]
  useEffect(() => {
    setTotalHours(orderList.map(o => o.hours).reduce((acc, h) => acc + h, 0))
    setTotalAmount(orderList.map(o => o.amount).reduce((acc, h) => acc + h, 0))
  }, [orderList])
  return (
    <>
      <ContainerSection>
        <TypographyText variant="h5">Customer:</TypographyText>
        <CustomerInfo
          customerName={customerName}
          setCustomerName={setCustomerName}
          customerCompanyName={customerCompanyName}
          setCustomerCompanyName={setCustomerCompanyName}
          customerTax={customerTax}
          setCustomerTax={setCustomerTax}
          customerEmail={customerEmail}
          setCustomerEmail={setCustomerEmail}
        />
      </ContainerSection>
      <ContainerSection>
        <TypographyText variant="h5">Order:</TypographyText>
        <List>
          {orderList.map((p, i) => (
            <ListItemBox key={i}>
              <OrderItem
                orderList={orderList}
                setOrderList={setOrderList}
                item={p}
                index={i}
              />
            </ListItemBox>
          ))}
        </List>
        <ChipBoxContainer>
          <ChipBox variant="outlined" label={`Hours total: ${totalHours}`} />
          <ChipBox
            variant="outlined"
            label={`Amount total: ${currencyBadge}${toPrice(totalAmount)}`}
          />
        </ChipBoxContainer>
      </ContainerSection>
      <ContainerSection>
        <TypographyText variant="h5">Offer:</TypographyText>
        <List>
          <ListItemBox>
            <OfferCustomItem
              orderList={orderList}
              setOrderList={setOrderList}
            />
          </ListItemBox>
          {offerList.map((p, i) => (
            <ListItemBox key={i}>
              <OfferItem
                orderList={orderList}
                setOrderList={setOrderList}
                item={p}
                currencyBadge={currencyBadge}
              />
            </ListItemBox>
          ))}
        </List>
      </ContainerSection>
    </>
  )
}

export default OrderCreator
