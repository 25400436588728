import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import {
  TextFieldLabel
} from './styles'

const OfferCustomItem = ({ orderList, setOrderList }) => {
  const [title, setTitle] = useState('')
  const [rate, setRate] = useState('')
  return (
    <Card>
      <IconButton
        onClick={() => {
          if (!title || !rate) return console.error('Set Title and Rate!')
          const newOrderList = [...orderList]
          newOrderList.push({ title, rate })
          setOrderList(newOrderList)
          setTitle('')
          setRate('')
        }}
        aria-label="Add"
      >
        <Icon>person_add</Icon>
      </IconButton>
      <TextFieldLabel
        label="Title"
        margin="normal"
        value={title}
        onChange={e => setTitle(e.currentTarget.value)}
      />{' '}
      <TextFieldLabel
        label="Rate"
        margin="normal"
        type="number"
        value={rate}
        onChange={e => setRate(e.currentTarget.value)}
      />
    </Card>
  )
}

export default OfferCustomItem
