import styled from 'styled-components'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'


export const ChipBoxContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const ChipBox = styled(Chip)`
  margin: 10px !important;
`

export const ButtonBox = styled(Button)`
  margin: 10px !important;
`