import React, { useState, useEffect } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import { CardBox, TextFieldLabel } from './styles'

const OrderItem = ({ orderList, setOrderList, item, index }) => {
  const { title: initTitle, rate: initRate } = item
  const [hours, setHours] = useState(1)
  const [amount, setAmount] = useState(1)
  const [rate, setRate] = useState(initRate || 1)
  const [title, setTitle] = useState(initTitle || '')
  useEffect(() => {
    const newOrderList = [...orderList]
    const newAmount = hours * rate
    newOrderList[index].hours = hours
    newOrderList[index].amount = newAmount
    setOrderList(newOrderList)
    setAmount(newAmount)
  }, [hours, rate])
  return (
    <CardBox>
      <IconButton
        onClick={() => {
          const newOrderList = [...orderList]
          delete newOrderList[index]
          setOrderList(newOrderList)
        }}
        aria-label="Delete"
      >
        <Icon>delete</Icon>
      </IconButton>
      <TextFieldLabel
        value={title}
        label="Title"
        margin="normal"
        onChange={e => {
          setTitle(e.currentTarget.value || '')
        }}
      />{' '}
      <TextFieldLabel
        value={rate}
        label="Rate"
        margin="normal"
        type="number"
        onChange={e => {
          setRate(+e.currentTarget.value || 0)
        }}
      />{' '}
      <TextFieldLabel
        value={hours}
        label="Hours"
        margin="normal"
        type="number"
        onChange={e => {
          setHours(+e.currentTarget.value || 0)
        }}
      />{' '}
      <TextFieldLabel value={amount} label="Amount" margin="normal" disabled />
    </CardBox>
  )
}

export default OrderItem
