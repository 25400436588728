import styled from 'styled-components'
import Card from '@material-ui/core/Card'

import TextField from '@material-ui/core/TextField'


export const CardBox = styled(Card)`
  width: 100%;
  max-width: 480px;
  display: flex!important;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 15px!important;
  box-sizing: border-box;

`
export const TextFieldLabel = styled(TextField)`
  width: 100%;
  max-width: 30%;
  padding: 0 10px!important;
  box-sizing: border-box;
  
  &:first-of-type{
    max-width: 80%;
  }
`