import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import ListItem from '@material-ui/core/ListItem'


export const ContainerSection = styled.div`
  width: 100%;
`
export const ListItemBox = styled(ListItem)`
  @media only screen and (max-width: 480px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`
export const TypographyText = styled(Typography)`
  margin-top: 20px !important;
  &:first-of-type{
    margin-bottom: 20px!important;
  }
`
export const ChipBoxContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const ChipBox = styled(Chip)`
  margin-right: 10px !important;
  margin-left: 10px !important;
`