import React from 'react'
import Card from '@material-ui/core/Card'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import { TextFieldLabel } from './styles'

const OfferItem = ({ orderList, setOrderList, item, currencyBadge }) => {
  const { title, rate } = item
  return (
    <Card>
      <IconButton
        onClick={() => {
          const newOrderList = [...orderList]
          newOrderList.push(item)
          setOrderList(newOrderList)
        }}
        aria-label="Add"
      >
        <Icon>person_add</Icon>
      </IconButton>
      <TextFieldLabel value={title} label="Title" margin="normal" disabled />{' '}
      <TextFieldLabel
        value={`${currencyBadge}${rate}/h`}
        label="Rate"
        margin="normal"
        disabled
      />
    </Card>
  )
}

export default OfferItem
