import React, { useState, useRef } from 'react'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import NotificationSystem from 'react-notification-system'
import PaymentForm from '../components/paymentForm/PaymentForm'
import OrderCreator from '../components/orderCreator/OrderCreator'
import { toDiscord } from '../components/helpers'
import './main.css'

const IndexPage = () => {
  const [totalAmount, setTotalAmount] = useState(0)
  const [totalHours, setTotalHours] = useState(0)
  const [orderList, setOrderList] = useState([])
  const [customerName, setCustomerName] = useState('')
  const [customerCompanyName, setCustomerCompanyName] = useState('')
  const [customerTax, setCustomerTax] = useState('')
  const [customerEmail, setCustomerEmail] = useState('')

  const ENV = process.env.GATSBY_IS_PROD ? 'prod' : 'dev'
  const apiUrl = `https://7zzqm97xr4.execute-api.us-east-2.amazonaws.com/${ENV}`
  const currencyBadge = '€'

  const notificationSystem = useRef(null)

  const showNotification = (status, text) => {
    const notification = notificationSystem.current
    notification.addNotification({
      title: text,
      level: status,
      autoDismiss: 0,
      position: 'bc',
    })
  }

  const logToDiscord = async (amount, status) => {
    await toDiscord(`
    -------------------------
      Name: ${customerName}
      Company: ${customerCompanyName}
      TAX: ${customerTax}
      Email: ${customerEmail}
      Amount: ${currencyBadge}${amount}
      Status: ${status}
      Date: ${new Date()}
    -------------------------
    `)
  }

  return (
    <>
      <Container maxWidth="md">
        <Box m={1}>
          <Paper>
            <Box p={1} className="order-creator-wrapper">
              <OrderCreator
                setTotalAmount={setTotalAmount}
                totalAmount={totalAmount}
                setTotalHours={setTotalHours}
                totalHours={totalHours}
                setOrderList={setOrderList}
                customerName={customerName}
                setCustomerName={setCustomerName}
                customerCompanyName={customerCompanyName}
                setCustomerCompanyName={setCustomerCompanyName}
                customerTax={customerTax}
                setCustomerTax={setCustomerTax}
                customerEmail={customerEmail}
                setCustomerEmail={setCustomerEmail}
                orderList={orderList}
                currencyBadge={currencyBadge}
              />
            </Box>
          </Paper>
        </Box>
        <Box m={1}>
          <PaymentForm
            totalAmount={totalAmount}
            totalHours={totalHours}
            logToDiscord={logToDiscord}
            showNotification={showNotification}
            apiUrl={apiUrl}
            currencyBadge={currencyBadge}
          />
        </Box>
      </Container>
      <NotificationSystem ref={notificationSystem} />
    </>
  )
}

export default IndexPage
