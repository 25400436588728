import React from 'react'

import {
  TextFieldLabel
} from './styles'

const CustomerInfo = ({
  customerName,
  setCustomerName,
  customerCompanyName,
  setCustomerCompanyName,
  customerTax,
  setCustomerTax,
  customerEmail,
  setCustomerEmail,
}) => {
  return (
    <>
      <div>
        <TextFieldLabel
          value={customerName}
          label="Full Name"
          margin="normal"
          onChange={e => setCustomerName(e.currentTarget.value)}
        />
      </div>
      <div>
        <TextFieldLabel
          value={customerCompanyName}
          label="Company Name"
          margin="normal"
          onChange={e => setCustomerCompanyName(e.currentTarget.value)}
        />
      </div>
      <div>
        <TextFieldLabel
          value={customerTax}
          label="TAX ID (if applicable)"
          margin="normal"
          onChange={e => setCustomerTax(e.currentTarget.value)}
        />
      </div>
      <div>
        <TextFieldLabel
          value={customerEmail}
          label="Email"
          margin="normal"
          onChange={e => setCustomerEmail(e.currentTarget.value)}
        />
      </div>
    </>
  )
}

export default CustomerInfo
